function Header(){
    return (
        <footer className="footer bg-neutral text-neutral-content items-center p-4">
            <aside className="grid-flow-col items-center">
                <p>CS506 - Cumulonimbus Cloud Computing  © {new Date().getFullYear()} - No rights reserved</p>
            </aside>

        </footer>


    );

}

export default Header;